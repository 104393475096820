.container {
  height: auto;
  background-color: rgb(86, 159, 195);
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu img {
  width: 40%;
  height: auto;
  border-radius: 20% 0 20% 0;
  padding: 3rem;
}
