/* CSS for the container */
.container {
  margin: 0 auto;
  background-color: rgb(86, 159, 195);
}

.food {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  width: 40%;
  height: auto;
  font-size: 20px;
}

/* CSS for the 'Om oss' section */
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.om {
  font-size: 40px;
  font-weight: bold;
}

.openingHours {
  font-weight: bold;
  font-size: 25px;
}

/* CSS for the iframe */
.iframe {
  flex: 1;
  text-align: right;
}
