.header {
  background-color: rgb(86, 159, 195);
  height: 8rem;
  margin-top: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.takeaway {
  top: 0;
  background-color: rgb(9, 89, 210);
  color: white;
  display: flex;
  padding: 1px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  font-weight: bold;
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.header button {
  background-color: transparent;
  border: none;
}

.header button:hover {
  transform: scale(1.1);
}

.logoButton {
  margin-right: auto;
  padding: 5rem;
}

.icons {
  font-size: 2.5rem;
  padding: 1rem;
}

.cart {
  margin-left: auto;
  padding: 3rem;
}
